"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isAppointmentClose = exports.isManualDonor = void 0;
const types_1 = require("./types");
const index_1 = require("./index");
function isManualDonor(donorId) {
    return donorId === types_1.MANUAL_DONOR_ID;
}
exports.isManualDonor = isManualDonor;
// Days between the donation date and the current day date (1 = 24 hours, 2 = 48 hours, ...)
const DAYS_LIMIT_BETWEEN_DATES = 2;
function isAppointmentClose({ donationStartTimeMillis, bookingTimeMillis, }) {
    const currentDayTime = new Date();
    const numberOfDaysBetweenDates = index_1.DateUtils.getNumberOfDaysBetweenDates(donationStartTimeMillis, currentDayTime.getTime());
    const currentDayMillis = currentDayTime.getTime();
    // Checks if the donation is in the time frame
    if (numberOfDaysBetweenDates > DAYS_LIMIT_BETWEEN_DATES ||
        numberOfDaysBetweenDates < 0) {
        return false;
    }
    // If appointment was booked more than 20s ago
    // Without this, a user could schedule on Sunday an appointment starting on Monday,
    // and then re-open the app on Monday and see the popup.
    // Here we verify that the appointment was booked very recently
    if (currentDayMillis - bookingTimeMillis > 20000) {
        return false;
    }
    return true;
}
exports.isAppointmentClose = isAppointmentClose;
