"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mixpanelConfigProd = exports.mixpanelConfigDev = exports.getMixpanelConfig = void 0;
const isProductionEnvironment = process.env.REACT_APP_PRODUCTION_FIREBASE;
function isProduction() {
    return isProductionEnvironment;
}
function getMixpanelConfig() {
    if (isProduction()) {
        console.log("Using production mixpanel");
        return exports.mixpanelConfigProd;
    }
    else {
        console.log("Using dev firebase");
        return exports.mixpanelConfigDev;
    }
}
exports.getMixpanelConfig = getMixpanelConfig;
exports.mixpanelConfigDev = {
    token: "52d74bb94b65b22516cf0660ef7f7dfa",
    debug: true,
};
exports.mixpanelConfigProd = {
    token: "5d8bd357809d54e97db4d05da6baad4f",
    debug: false,
};
