"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getPhoneCall = exports.getWhatsAppLinkWithText = void 0;
function getWhatsAppLinkWithText(text, phoneNumber = "") {
    const WHATSAPP_LINK = "https://wa.me/972" + (phoneNumber || "0587100571");
    return `${WHATSAPP_LINK}?text=${text}`;
}
exports.getWhatsAppLinkWithText = getWhatsAppLinkWithText;
function getPhoneCall(phoneNumber) {
    const PHONE_NUMBER = `tel:+${phoneNumber}`;
    return `${PHONE_NUMBER}`;
}
exports.getPhoneCall = getPhoneCall;
